import axios from '@axios'

export default {
  namespaced: true,
  state: {
    carteiraData: null,
  },
  getters: {},
  mutations: {
    SET_CARTEIRA_DATA(state, data) {
      state.carteiraData = data
    },
  },

  actions: {

    getCarteira({ commit, state }, cache) {
      if (cache === true && state.carteiraData != null) {
        return state.carteiraData
      }
      return new Promise((resolve, reject) => {
        axios.get('/fut/usuario-info/carteira')
          .then(response => {
            commit('SET_CARTEIRA_DATA', response.data)
            resolve(response.data)
          })
          .catch(error => { reject(error) })
      })
    },

    getCreditoDesempenho() {
      return new Promise((resolve, reject) => {
        axios.get('/usuario-info/credito')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSaldo(context, symbol) {
      return new Promise((resolve, reject) => {
        axios.get(`/fut/usuario-info/saldo/${symbol}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    isVerificaAssinaturaAtiva() {
      return new Promise((resolve, reject) => {
        axios.get('/fut/usuario-info/verifica-assinatura/futures')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

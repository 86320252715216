<template>
  <div>
    <b-row
      v-if="windowWidth >= 800"
    >
      <b-col lg="12">
        <b-card>
          <TradingViewWidgets :options="{ theme: isThemeDark ? 'dark' : 'light' }" />
        </b-card>
      </b-col>
    </b-row>

    <b-overlay
      :show="loadingOverlayCarteira"
      rounded="sm"
    >
      <b-row>
        <termo-de-uso />
        <b-col
          lg="12"
        >
          <b-card>
            <b-card-text
              v-if="carteiraAtualizacaoElapsed!=null"
              class="mb-0 d-flex justify-content-end align-items-center"
            >
              <b-button
                v-b-tooltip.hover.top="$t('futures.textTooltipAtuaizar')"
                class="btn-icon"
                variant="flat-secondary"
                pill
                @click="atualizarDados()"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                />
              </b-button>
              <template v-if="errorLoadCarteira==null">
                {{ $t('futures.textLoadSuccessCarteira') }} {{ carteiraAtualizacaoElapsed }}
              </template>
              <template v-else>
                {{ $t('futures.textErroLoadCarteira') }}
              </template>
            </b-card-text>

            <b-card-body v-if="errorLoadCarteira==null">

              <b-row>
                <b-col
                  lg="6"
                >
                  <b-card
                    no-body
                    class="card-detach"
                  >
                    <b-card-body>
                      <b-card-title> {{ $t('futures.textValorEstimado') }}</b-card-title>
                      <b-row>
                        <b-col
                          v-if="estimativaUSD"
                          lg="4"
                        >
                          <div class="truncate">
                            <h2 class="font-large-1 font-weight-bolder p-1">
                              $ {{ estimativaUSD }}
                            </h2>
                          </div>
                        </b-col>
                        <b-col lg="6">
                          <vue-apex-charts
                            type="donut"
                            height="140"
                            :options="carteiraGrafico"
                            :series="seriesCarteira"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>

                <b-col
                  v-if="creditoDesempenho"
                  lg="4"
                >
                  <b-card
                    no-body
                    class="card-detach"
                  >
                    <b-card-body class="mb-1">
                      <b-card-title> {{ $t('futures.textCreditoTaxa') }}</b-card-title>
                      <h2 class="font-large-1 font-weight-bolder p-1">
                        $ {{ toFixed(creditoDesempenho.saldo, 2) }}
                      </h2>
                      <router-link
                        class="card-link"
                        :to="{ name: 'checkout-credito' }"
                      >
                        {{ $t('futures.textAdicionarSaldo') }}
                      </router-link>
                      <b-link
                        class="card-link"
                        :to="{ name: 'conta-credito-extrato'}"
                      >
                        {{ $t('futures.textExtrato') }}
                      </b-link>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>

            </b-card-body>
            <b-card-body v-else>
              {{ errorLoadCarteira }}
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="4"
        >
          <b-card
            class="mb-3"
            :title="$t('futures.titleCardCarteira')"
          >
            <b-card-body>
              <b-col
                v-for="car in carteira"
                :key="car.simbolo"
                lg="12"
              >
                <b-card class="card-detach">
                  <div class="demo-inline-spacing mb-2">
                    <b-avatar
                      :src="`https://cryptoboot.com.br/repo/icons/${car.simbolo.toLowerCase()}.png`"
                      size="32px"
                      class="align-baseline"
                      style="background-color: transparent"
                    />
                    <span class="font-weight-bolder">
                      {{ car.simbolo }}
                    </span>
                  </div>
                  <div class="truncate">
                    <h2 class="font-weight-bolder">
                      {{ toFixed(car.valor, 6) }}
                    </h2>
                  </div>
                </b-card>
              </b-col>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          class="mb-gap"
        >

          <b-card class="earnings h-100 card-revenue-budget">
            <vue-apex-charts
              type="line"
              height="200"
              :options="cardFinanceiroGanhoMesesChartOptions"
              :series="cardFinanceiroGanhoMesesSeries.series"
            />
            <div class="earnings-overall">
              <b-row>
                <b-col
                  lg="7"
                  sm="7"
                >
                  <h4>
                    {{ $t('futures.titleCardFinanceiro') }}
                  </h4>
                  <small class="mt-1">Total</small>
                  <p class="mb-1">
                    $ {{ cardFinanceiroGanhoMesAtual }}
                  </p>
                  <legend v-if="cardFinanceirodiferencaPercentual">
                    <span class="font-weight-bolder">{{ cardFinanceirodiferencaPercentual }}%</span> {{ $t('futures.subtitleCardFinanceiro') }}
                  </legend>
                </b-col>
                <b-col
                  v-show="true"
                  lg="5"
                  sm="5"
                >
                  <vue-apex-charts
                    v-show="false"
                    type="donut"
                    height="170"
                    class="donut-graph"
                    :options="cardFinanceiroGanhoMesChart.chartOptions"
                    :series="cardFinanceiroGanhoMesChart.series"
                  />
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          class="mb-gap"
        >
          <b-card
            :title="$t('futures.titleCardAssertividadeMensal')"
            class="h-100"
          >
            <b-row>
              <b-col lg="12">
                <grafico-sinal-fut
                  :resumo-sinal="resumoSinal"
                  :series="seriesSinal"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="12"
        >
          <b-card
            class="mb-3"
          >
            <b-card-title>
              <b-avatar
                src="https://cryptoboot.com.br/repo/icons/usdt.png"
                size="26px"
                class="mr-1"
              />
              {{ $t('futures.titleCardInvestimento') }}
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col
                  lg="3"
                  sm="5"
                >
                  <b-card
                    bg-variant="success"
                    text-variant="white"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="26"
                    />
                    <div class="truncate">
                      <h5 class="my-1 text-white">
                        {{ $t('futures.textSaldo') }}
                      </h5>
                      <h3
                        v-show="saldoTotalUSDTCarteira"
                        class="font-weight-bolder text-white"
                      >
                        {{ toFixed(saldoTotalUSDTCarteira, 2) }}
                      </h3>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  lg="3"
                  sm="5"
                >
                  <b-card>
                    <feather-icon
                      icon="BarChart2Icon"
                      size="26"
                    />
                    <div class="truncate">
                      <h5 class="my-1">
                        {{ $t('futures.textSaldoMargem') }}
                      </h5>
                      <h3
                        v-show="saldoMargemUSDTCarteira"
                        class="font-weight-bolder"
                      >
                        {{ parseFloat(saldoMargemUSDTCarteira).toFixed(2) }}
                      </h3>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  lg="3"
                  sm="5"
                >
                  <b-card>
                    <feather-icon
                      icon="ActivityIcon"
                      size="26"
                    />
                    <div class="truncate">
                      <h5 class="my-1">
                        {{ $t('futures.textOperacoesAbertas') }}
                      </h5>
                      <h3
                        v-show="unPnlUSDTCarteira"
                        class="font-weight-bolder"
                      >
                        {{ parseFloat(unPnlUSDTCarteira).toFixed(2) }}
                      </h3>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  lg="3"
                  sm="5"
                >
                  <b-card>
                    <feather-icon
                      icon="TrendingUpIcon"
                      size="26"
                    />
                    <div class="truncate">
                      <h5 class="my-1">
                        {{ $t('futures.textDisponivelOperar') }}
                      </h5>
                      <h3
                        v-if="disponivelUSD"
                        class="font-weight-bolder"
                      >
                        {{ toFixed(disponivelUSD, 2) }}
                      </h3>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-card
            :title="$t('futures.titleTerceiroCard')"
          >
            <b-card-body>
              <b-table
                ref="refListTable"
                :items="dataListUltimasVendas"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                :empty-text="$t('futures.emptyText')"
                class="font-weight-bold"
              >
                <template #cell(paridade)="data">
                  <div class="text-nowrap py-1">
                    <b-avatar
                      size="md"
                      :src="getIcon(data.item.moedaTrade)"
                      style="background-color: transparent"
                    />
                    <span class="mx-1">
                      {{ data.item.moedaTrade }}
                    </span>
                  </div>
                </template>
                <template #cell(ordemPosition)="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                    <b-badge variant="light-warning ml-2">
                      {{ data.item.alavancagem }}x
                    </b-badge>
                  </div>
                </template>
                <template #cell(resultado)="data">
                  <b-badge :variant="parseFloat(data.item.pnl) >= 0? 'light-success' :'light-danger'">
                    <feather-icon
                      :icon="parseFloat(data.item.pnl) >= 0? 'ArrowUpIcon': 'ArrowDownIcon'"
                    />
                    {{ toFixed(data.item.pnl, 4) }} {{ data.item.paridadeTrade }}
                  </b-badge>
                </template>

                <template #cell(duracao)="data">
                  {{ duracao(data.item.dataCriacaoCompra, data.item.dataExecucao) }}
                </template>

                <template #cell(dataCriacaoCompra)="data">
                  {{ formatDateBR(data.value) }}
                  as
                  {{ formatHourBR(data.value) }}
                </template>

                <template #cell(agente)="data">
                  {{ data.value }}
                  <b-badge
                    variant="light-primary"
                    class="ml-1"
                  >
                    #{{ data.item.sinalId }}
                  </b-badge>
                </template>

              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BAvatar, BRow, BCol, BCardTitle, BOverlay, BCardText, BCardBody, VBTooltip, BButton, BLink,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import TradingViewWidgets from '@/layouts/components/custom/TradingViewWidgets.vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import {
  formatHourBR, formatDateBR, percentDiferenca, duracao, toIsoStringTimeZone,
} from '@/utils/filter'
import Ripple from 'vue-ripple-directive'
import fechamentoStoreModule from './relatorio/fechamento/fechamentoStoreModule'
import posicaoStoreModule from './posicao/posicaoStoreModule'
import usuarioStoreModule from './usuario/usuarioStoreModule'
import TermoDeUso from '../termos/TermoDeUso.vue'

const RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME = 'fechamento-relatorio-fut'
const POSICAO_FUT_STORE_MODULE_NAME = 'posicao-fut'
const USUARIO_FUT_STORE_MODULE_NAME = 'usuario-fut'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BCardTitle,
    TradingViewWidgets,
    VueApexCharts,
    BOverlay,
    BCardText,
    BCardBody,
    BButton,
    BLink,
    TermoDeUso,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      errorLoadCarteira: null,
      loadingOverlayCarteira: false,
      loadingOverlaySinal: false,
      loadingOverlayFinanceiro: false,
      formatHourBR,
      formatDateBR,
      percentDiferenca,
      duracao,
      dataListUltimasVendas: [],
      resumoSinal: [],
      seriesSinal: [],
      estimativaUSD: '',
      atualizacaoCarteira: '',
      dataAtual: new Date(),
      seriesCarteira: [],
      labelsCarteira: [],
      carteira: [],
      creditoDesempenho: null,
      saldoTotalUSDTCarteira: '',
      saldoMargemUSDTCarteira: '',
      unPnlUSDTCarteira: '',
      dataListFinanceiro: [],
      cardFinanceiroGanhoMesAtual: '',
      cardFinanceiroGanhoMesChart: {
        series: [100, 100],
        chartOptions: {
          legend: {
            show: false,
          },
          colors: [
            '#288D70',
            '#2B7461',
          ],
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270,
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '12px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '12px',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '12px',
                    label: 'Total',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${val.config.series[0]}%`
                    },
                  },
                },
              },
            },
          },
          labels: ['Total', ''],
        },
      },
      cardFinanceiroGanhoMesesCategories: [],
      cardFinanceiroGanhoMesesSeries: {
        series: [
          {
            name: 'USDT',
            data: [],
          },
        ],
      },
    }
  },

  computed: {
    tableColumns() {
      return [
        {
          key: 'paridade', label: this.$i18n.t('futures.tableHeader.paridade'), tdClass: 'text-dark recentTransactionCell',
        },
        {
          key: 'ordemPosition', label: this.$i18n.t('futures.tableHeader.ordemPosition'), tdClass: 'text-dark recentTransactionCell',
        },
        {
          key: 'quantidade', label: this.$i18n.t('futures.tableHeader.quantidade'), tdClass: 'text-dark recentTransactionCell',
        },
        {
          key: 'resultado', label: this.$i18n.t('futures.tableHeader.resultado'), tdClass: 'text-dark recentTransactionCell text-center', thClass: 'text-center',
        },
        {
          key: 'dataCriacaoCompra', label: this.$i18n.t('futures.tableHeader.dataCriacaoCompra'), tdClass: 'text-dark recentTransactionCell text-center', thClass: 'text-center',
        },
        {
          key: 'duracao', label: this.$i18n.t('futures.tableHeader.duracao'), tdClass: 'text-dark recentTransactionCell',
        },
        {
          key: 'agente', label: this.$i18n.t('futures.tableHeader.agente'), tdClass: 'text-dark recentTransactionCell',
        },
      ]
    },

    carteiraAtualizacaoElapsed() {
      if (this.atualizacaoCarteira != null) {
        return this.elapsedTime2(this.atualizacaoCarteira, this.dataAtual)
      }
      return null
    },

    isThemeDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },

    carteiraGraficoBar() {
      const chartOptions = {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#26A17B', '#F3BA2F', '#00e396', '#82868b', '#775dd0'],
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: '10%',
            colors: {
              backgroundBarColors: [
                '#313A49',
                '#313A49',
                '#313A49',
                '#313A49',
                '#313A49',
                '#313A49',
                '#313A49',
                '#313A49',
              ],
              backgroundBarRadius: 7,
            },
          },
        },
        labels: this.labelsCarteira,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.labelsCarteira,
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          // opposite: isRtl,
          categories: [''],
          labels: {
            show: false,
          },

        },
        fill: {
          opacity: 1,
        },
      }
      return chartOptions
    },

    carteiraGrafico() {
      const chartOptions = {
        noData: {
          text: 'Loading...',
        },
        labels: this.labelsCarteira,
        dataLabels: {
          enabled: false,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        colors: ['#26A17B', '#F3BA2F', '#00e396', '#82868b', '#775dd0'],
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: 'Montserrat',
          formatter(seriesName, opts) {
            return [`<span class='font-weight-bolder'>${seriesName} - </span>`, '  ', parseFloat(opts.w.globals.series[opts.seriesIndex]).toFixed(4)]
          },
        },
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
                name: {
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                },
                value: {
                  show: false,
                  fontSize: '12px',
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: false,
                  fontSize: '12px',
                  label: 'Total',
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${val.config.series[0]}`
                  },
                },
              },
            },
          },
        },
      }
      return chartOptions
    },

    cardFinanceiroGanhoMesesChartOptions() {
      const chartOptions = {
        noData: {
          text: 'Loading...',
        },
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        grid: {
          show: true,
          borderColor: '#90A4AE',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#28c76f'],
        markers: {
          size: 5,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
          categories: this.cardFinanceiroGanhoMesesCategories,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },

        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
            },
          },
        },
      }
      return chartOptions
    },

    cardFinanceirodiferencaPercentual() {
      const desempenho = this.dataListFinanceiro
      const keys = Object.keys(desempenho)
      if (keys.length < 2) {
        return 'Insufficient data'
      }

      const ultimoMes = keys[keys.length - 1]
      const penultimoMes = keys[keys.length - 2]

      const valorUltimoMes = desempenho[ultimoMes]
      const valorPenultimoMes = desempenho[penultimoMes]

      if (valorPenultimoMes === 0) {
        return 'Cannot calculate '
      }

      const diferenca = valorUltimoMes - valorPenultimoMes
      const diferencaPercentual = (diferenca / valorPenultimoMes) * 100

      // Arredonda o valor para 2 casas decimais
      return `${diferencaPercentual.toFixed(2)}`
    },

  },

  created() {
    if (!store.hasModule(USUARIO_FUT_STORE_MODULE_NAME)) store.registerModule(USUARIO_FUT_STORE_MODULE_NAME, usuarioStoreModule)
    this.isVerificaAssinaturaAtiva()
    if (!store.hasModule(POSICAO_FUT_STORE_MODULE_NAME)) store.registerModule(POSICAO_FUT_STORE_MODULE_NAME, posicaoStoreModule)
    if (!store.hasModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)) store.registerModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME, fechamentoStoreModule)
    this.pollDataAtual()
    this.getCarteira(true)
    this.getPosicaoResumo()
    this.getUltimasPosicoesFinalizadas()
    this.getFinanceiroResumoHome()
  },

  beforeDestroy() {
    if (store.hasModule(POSICAO_FUT_STORE_MODULE_NAME)) store.unregisterModule(POSICAO_FUT_STORE_MODULE_NAME)
    if (store.hasModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)) store.unregisterModule(RELATORIO_FECHAMENTO_FUT_STORE_MODULE_NAME)
    if (store.hasModule(USUARIO_FUT_STORE_MODULE_NAME)) store.unregisterModule(USUARIO_FUT_STORE_MODULE_NAME)
  },

  methods: {

    isVerificaAssinaturaAtiva() {
      store
        .dispatch('usuario-fut/isVerificaAssinaturaAtiva')
        .then(response => {
          if (response.data !== true) {
            this.$router.push({ name: 'conta-assinatura' })
          }
        })
    },

    getUltimasPosicoesFinalizadas() {
      store
        .dispatch('fechamento-relatorio-fut/getUltimasPosicoesFinalizadas')
        .then(response => {
          this.dataListUltimasVendas = response.data.content
        })
    },

    getPosicaoResumo() {
      this.loadingOverlaySinal = true

      const currentDate = new Date()
      const primeiroDiaMes = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      const ultimoDiaMes = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

      const dataInicio = toIsoStringTimeZone(primeiroDiaMes)
      const dataFim = toIsoStringTimeZone(ultimoDiaMes)

      store
        .dispatch('posicao-fut/getPosicaoResumoHome', {
          dataInicio,
          dataFim,
        })
        .then(response => {
          this.seriesSinal = [response.data.totalAtivoPercent, response.data.totalGanhoPercent, response.data.totalPerdaPercent]
          this.resumoSinal = response.data
        })
        .finally(() => {
          this.loadingOverlaySinal = false
        })
    },

    getFinanceiroResumoHome() {
      this.loadingOverlayFinanceiro = true

      store
        .dispatch('fechamento-relatorio-fut/getFinanceiroResumoHome')
        .then(response => {
          this.dataListFinanceiro = response.data.desempenho
          const chaves = Object.keys(this.dataListFinanceiro)
          const ultimaChave = this.dataListFinanceiro[chaves[chaves.length - 1]]
          this.cardFinanceiroGanhoMesAtual = parseFloat(ultimaChave || 0).toFixed(2)
          this.cardFinanceiroGanhoMesesCategories = chaves
          const seriesData = Object.values(this.dataListFinanceiro).map(value => Math.round(value))

          this.cardFinanceiroGanhoMesesSeries = {
            series: [{
              data: seriesData,
            }],
          }
        })
        .finally(() => {
          this.loadingOverlayFinanceiro = false
        })
    },

    atualizarDados() {
      this.getCarteira(false)
      this.getUltimasPosicoesFinalizadas()
      this.getPosicaoResumo()
    },

    getCarteira(cache) {
      this.loadingOverlayCarteira = true

      store
        .dispatch('usuario-fut/getCarteira', cache)
        .then(response => {
          this.errorLoadCarteira = null
          this.estimativaUSD = response.estimativaUSD
          this.atualizacaoCarteira = response.atualizacao
          this.saldoTotalUSDTCarteira = response.balanceUSD
          this.saldoMargemUSDTCarteira = response.marginBalanceUSD
          this.unPnlUSDTCarteira = response.unrealizedPNLUSD
          this.disponivelUSD = response.disponivelUSD
          this.saldoMargemUSDTCarteiraPerc = response.marginBalanceUSDPerc
          this.unPnlUSDTCarteiraPerc = response.unrealizedPNLUSDPerc
          this.disponivelUSDPerc = response.disponivelUSDPerc
          this.carteira = response.carteira
          const seriesPrincipal = []
          const labelsPrincipal = []

          if (response.carteira != null) {
            response.carteira.forEach(value => {
              seriesPrincipal.push(value.valor)
              labelsPrincipal.push(value.simbolo)
            })
          }
          this.seriesCarteira = seriesPrincipal
          this.labelsCarteira = labelsPrincipal
          // this.seriesBar = [{ data: seriesDataBar }]
        }).catch(e => {
          this.errorLoadCarteira = e.data
          this.estimativaUSD = 0
          this.atualizacaoCarteira = null
          this.saldoTotalUSDTCarteira = 0
          this.saldoMargemUSDTCarteira = 0
          this.unPnlUSDTCarteira = 0
          this.disponivelUSD = 0
          this.saldoMargemUSDTCarteiraPerc = 0
          this.unPnlUSDTCarteiraPerc = 0
          this.disponivelUSDPerc = 0
          this.seriesCarteira = []
          this.labelsCarteira = []
          this.seriesBar = []
          this.carteira = []
        }).finally(() => {
          this.loadingOverlayCarteira = false
        })

      store
        .dispatch('usuario-fut/getCreditoDesempenho', cache)
        .then(response => {
          this.creditoDesempenho = response.data
        }).catch(() => {
          this.creditoDesempenho = null
        })
    },

    pollDataAtual() {
      const self = this
      this.polling = setInterval(() => {
        self.dataAtual = new Date()
      }, 10000)
    },

  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/vue/libs/chart-apex.scss';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
  .card-detach {
    background-color: $theme-dark-border-color !important;
  }
}

.card-detach {
  background-color: #f6f6f6 !important;
}

.mt-gap {
  margin-top: 28px;
}
.mb-gap {
  margin-bottom: 28px;
}

.earnings {
  background: #111727;
  border-radius: 0.428rem;

  &-overall {
    border-radius: 8px;
    background: #313A49;
    padding: 18px 22px;
    margin: 20px;
    color: #FFF;
    h4  {
      color: #FFF;
      font-size: 18px;
    }
    p {
      color: #FFF;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }
    legend {
      color: #FFF;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    small {
      color:  #BABFC7;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .donut-graph {
      pointer-events: none;
    }
  }
}

</style>

<style lang="scss">
  .recentTransactionCell {
    color: #101828 !important;
  }
</style>

import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/fut/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getPosicoesAbertas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/posicao/aberta', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    encerrarPosicao(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/fut/posicao/encerrar/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getPosicaoResumoHome(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/posicao/resumo/home', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
